import React from 'react';
import { Navigate } from 'react-router-dom';

import { AuthForm } from 'features/auth';

import f5logo from 'assets/logo_dark.png';

const AuthPage = () => {
  if (localStorage.getItem('access')) {
    return <Navigate to="/" replace={true} />;
  }

  return (
    <div className="h-screen bg-[url('assets/auth.png')] bg-cover flex flex-col justify-center">
      <div className="w-[1300px] flex items-start mx-auto">
        <div className=" mb-12 w-1/2 flex flex-col items-start">
          <div className="h-12 mb-8 ml-[200px]">
            <img
              src={f5logo}
              alt="Логотип"
              className="w-full h-full object-contain"
            />
          </div>
          <h1 className="font-semibold text-[30px] ml-[200px]">
            Цифровой помощник
            <br />
            диспетчера
          </h1>
        </div>
        <AuthForm />
      </div>
    </div>
  );
};

export default AuthPage;
