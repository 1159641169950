import React from 'react';
import { Button, Menu } from '@factory5/ui-kit';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { ArrowRightOnRectangleIcon } from '@heroicons/react/24/outline';
import { useStore } from 'effector-react';
import { Tooltip } from 'ui';
import { history } from 'lib';

import { RoleEnum } from 'api';

import { $me, User, resetMe } from 'features/auth';

import f5logo from 'assets/logo_dark.png';

const Header = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const user = useStore($me);

  const menuItems = [
    { key: '/', label: 'Станции', className: 'after:!-bottom-2.5' },
    {
      key: '/dislocation',
      label: 'Дислокация',
      className: 'after:!-bottom-2.5',
    },
    {
      key: '/proposed-trips',
      label: 'Прогнозные рейсы',
      className: 'after:!-bottom-2.5',
    },
    {
      key: 'admin',
      label: 'Администрирование',
      className: 'after:!-bottom-2.5',
    },
  ];

  if (user?.role !== RoleEnum.NUMBER_2) {
    menuItems.splice(menuItems.length - 1, 0, {
      key: '/management',
      label: 'Оптимизатор',
      className: 'after:!-bottom-2.5',
    });
  }

  const activeItemKey = menuItems.find((item) => {
    const firstPathOfPathname = pathname.split('/').filter((word) => word);
    if (firstPathOfPathname.length > 0) {
      return item.key === `/${firstPathOfPathname[0]}`;
    } else {
      return item;
    }
  })?.key;

  return (
    <div className="flex w-full h-full items-center justify-between">
      <div className="flex flex-row items-center">
        <Link to="/" className="block h-8 mr-10">
          <img
            src={f5logo}
            alt="Логотип"
            className="w-full h-full object-contain"
          />
        </Link>

        <Menu
          activeKey={activeItemKey}
          layout="horizontal"
          design="underline"
          items={menuItems}
          onClick={(key) => {
            if (key === 'admin') {
              history.push(
                `${
                  process.env.REACT_APP_BACKEND_URI
                    ? process.env.REACT_APP_BACKEND_URI
                    : 'https://dev01.gpt.xplanet.tech'
                }/admin/?token=${localStorage.getItem('access')}`,
              );
              return;
            }
            navigate(key);
          }}
        />
      </div>

      <div className="flex">
        <User />
        <Tooltip content="Выход">
          <Button
            onClick={() => resetMe()}
            icon={<ArrowRightOnRectangleIcon />}
            size="sm"
            className="ml-4"
          />
        </Tooltip>
      </div>
    </div>
  );
};

export default Header;
