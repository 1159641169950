import React from 'react';
import cx from 'classnames';
import Icon from 'ui/Icon';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { useStore } from 'effector-react';
import { capitalize, includes } from 'lodash';
import Tooltip from 'ui/Tooltip';

import { OperationEngLabelsEnum, StatusEngLabelsEnum } from 'api';

import { WagonDeploymentListItem } from 'features/stations/models/wagonDeplyment.types';
import {
  $selectedFilters,
  activateFilter,
  setFilterValues,
} from 'features/filters';
import { FilterRootKeys } from 'features/filters/models/filters.types';
import { ControlTripListItem } from 'features/stations/models/controlTrips.types';

import { SignList } from './SignList';
import ObjectiveStatus from './ObjectiveStatus';
import TrainStatusIcon from './TrainStatusIcon';

dayjs.extend(duration);

export type DislocationRowProps = {
  className?: string;
  wagonDeployment:
    | WagonDeploymentListItem
    | ControlTripListItem['current_trip'];
  filterKey: FilterRootKeys;
};

function getDaysFromDate(date: string, postfix = 'сут.') {
  const now = dayjs(dayjs().format('YYYY-MM-DD'));

  const isAfter = dayjs(date).isAfter(now);
  const isSame = dayjs(date).isSame(now);

  return `${isAfter || isSame ? '+' : ''}${dayjs
    .duration(dayjs(date).diff(now))
    .asDays()
    .toFixed()} ${postfix}`;
}

export default function DislocationRow({
  className,
  wagonDeployment,
  filterKey,
}: DislocationRowProps) {
  const navigate = useNavigate();
  const {
    access_remaining_days,
    access_remaining_distance,
    objective,
    car,
    departure_station,
    operation_station,
    destination_station,
    return_station,
    cargo,
    speed,
    speed_warning,
    first_leg_trip: proposed_trip,
    proposed_trips_count,
    op_duration,
    op_duration_exceed,
    utilization_type,
    return_waybill_status,
    is_empty,
    is_on_way,
    speed_norm,
  } = wagonDeployment;

  const operationStationIsSameDepartureOrDestination =
    operation_station?.name === departure_station?.name ||
    operation_station?.name === destination_station?.name;

  const filters = useStore($selectedFilters)[filterKey];

  return (
    <div
      className={cx('flex flex-col rounded-lg', className, {
        'bg-white shadow': utilization_type === 0 || utilization_type === 2,
        'border border-dashed border-stone-300': utilization_type === 1,
      })}
    >
      <div className="flex flex-row justify-between px-6 pt-4 pb-5 items-center border-b border-slate-100">
        <div className="flex flex-row gap-5 items-start">
          {/* Основа */}
          <div className="flex flex-row items-start w-80 pr-5 border-r border-slate-300 border-dashed">
            <div className="mr-5">
              <TrainStatusIcon
                is_on_way={is_on_way}
                objective={objective}
                filterKey={filterKey}
                accessRemainingDistance={access_remaining_distance}
                accessRemainingDays={access_remaining_days}
              />

              <div className="mt-8">
                {speed !== undefined && speed !== null ? (
                  <div className="flex flex-col text-center">
                    <Tooltip
                      content={`Скорость ниже норматива ${speed_norm} км/сут.`}
                      disabled={!speed_warning}
                    >
                      <div
                        className={cx(
                          { 'text-rose-500': speed_warning },
                          'text-base leading-none',
                        )}
                      >
                        {`${speed}`}
                      </div>
                    </Tooltip>
                    <div className="text-xxs leading-none">км/сут</div>
                  </div>
                ) : (
                  op_duration !== null && (
                    <div
                      className="flex flex-col text-center cursor-pointer"
                      onClick={() => {
                        activateFilter('op_duration');
                        setFilterValues({
                          rootKey: filterKey,
                          field: 'op_duration',
                          values: [op_duration, op_duration],
                        });

                        activateFilter('is_idle');
                      }}
                    >
                      <Tooltip content="Длительность текущей операции">
                        <div
                          className={cx(
                            { 'text-rose-500': speed_warning },
                            'text-base leading-none',
                          )}
                        >
                          {`${op_duration}`}
                        </div>
                      </Tooltip>
                      <div className="text-xxs leading-none">сут</div>
                    </div>
                  )
                )}
              </div>
            </div>

            <div className="flex flex-row">
              <div className="mr-5 w-20">
                <div className="mb-3.5 leading-none underline">{car.name}</div>

                <div className="flex flex-row ml-5 items-center">
                  <Tooltip
                    content={cx({
                      'Используется в расчёте': utilization_type === 0,
                      'Не допущен к расчёту': utilization_type === 1,
                      'Выведен из расчёта как неиспользуемый':
                        utilization_type === 2,
                    })}
                  >
                    <div
                      onClick={() => {
                        if (filterKey !== 'station') return;

                        activateFilter('utilization_type');
                        setFilterValues({
                          rootKey: filterKey,
                          field: 'utilization_type',
                          values: [
                            cx({
                              Используется: utilization_type === 0,
                              Отброшен: utilization_type === 1,
                              'Не используется': utilization_type === 2,
                            }),
                          ],
                        });
                      }}
                      className={cx('relative', {
                        'cursor-pointer': filterKey === 'station',
                      })}
                    >
                      <Icon
                        icon={
                          utilization_type === 0 || utilization_type === 2
                            ? 'check-circle'
                            : 'cross-circle'
                        }
                        className={cx('w-5', {
                          'text-green-600':
                            utilization_type === 0 || utilization_type === 2,
                          'text-red-600': utilization_type === 1,
                        })}
                      />
                      {utilization_type === 2 && (
                        <div className="absolute top-0 -right-1 text-xs font-semibold text-red-600">
                          !
                        </div>
                      )}
                    </div>
                  </Tooltip>{' '}
                  {utilization_type !== 1 && utilization_type !== 2 && (
                    <div className="text-xxs text-slate-400 underline ml-2">
                      {proposed_trips_count}
                    </div>
                  )}
                </div>
              </div>

              <div className="flex flex-col">
                {cargo && (
                  <Tooltip
                    content={
                      is_empty ? (
                        <div>
                          Порожний. Последний груз: <br />
                          {capitalize(cargo.name)}
                        </div>
                      ) : (
                        <div>
                          Гружёный. <br />
                          {capitalize(cargo.name)}
                        </div>
                      )
                    }
                  >
                    <div
                      className="flex flex-row gap-1 items-center rounded-lg border-[2px] border-dotted border-slate-400 text-[10px] font-semibold max-w-[118px] pl-1 pr-0.5 mb-4 leading-3.5 cursor-pointer"
                      onClick={() => {
                        activateFilter('cargo_name');
                        setFilterValues({
                          rootKey: filterKey,
                          field: 'cargo_name',
                          values: [cargo.name],
                        });
                      }}
                    >
                      <Icon
                        icon={is_empty ? 'oil-empty' : 'oil'}
                        className="max-w-[8px]"
                      />
                      <div className="truncate">{capitalize(cargo.name)}</div>
                    </div>
                  </Tooltip>
                )}

                <SignList
                  wagonDeployment={wagonDeployment}
                  filterKey={filterKey}
                />
              </div>
            </div>
          </div>

          {/* СТАНЦИЯ ОТПРАВЛЕНИЯ */}
          <div className="w-56 px-4">
            {departure_station && (
              <div>
                {operation_station?.name === departure_station.name &&
                  destination_station?.name !== operation_station?.name &&
                  destination_station?.name !== departure_station.name && (
                    <div className="relative">
                      <Icon
                        icon="flag"
                        className="w-1.5 text-rose-600 absolute top-1 -left-3"
                      />
                    </div>
                  )}
                <div className="uppercase font-semibold text-sm leading-none truncate">
                  <Tooltip
                    content={
                      <div>
                        {`ЕСР ${departure_station.code}`}
                        <br />
                        {departure_station.name}
                        <br />
                        {departure_station.railway}
                      </div>
                    }
                  >
                    <span
                      className="whitespace-nowrap cursor-pointer"
                      onClick={() => {
                        setFilterValues({
                          rootKey: filterKey,
                          field: 'from_station_name',
                          values: [departure_station.name],
                        });
                      }}
                    >
                      {departure_station.name}
                    </span>
                  </Tooltip>
                </div>
              </div>
            )}
          </div>

          {/* СТАНЦИЯ ОПЕРАЦИИ */}

          {operation_station && (
            <div className="w-56 px-4">
              {
                <div className="flex flex-row gap-2 leading-none items-start pt-1">
                  <div className="relative">
                    {!operationStationIsSameDepartureOrDestination && (
                      <Icon
                        icon="flag"
                        className="w-1.5 text-rose-600 absolute -left-1"
                      />
                    )}
                  </div>
                  <div>
                    <div className="text-xxs uppercase text-slate-500 font-semibold mb-[1.5px] truncate">
                      <Tooltip
                        content={
                          <div>
                            {`ЕСР ${operation_station.code}`}
                            <br />
                            {operation_station.name}
                            <br />
                            {operation_station.railway}
                          </div>
                        }
                      >
                        <span>{operation_station.name}</span>
                      </Tooltip>
                    </div>

                    {operation_station.date && (
                      <div>
                        <span className="text-xs text-slate-500 font-bold">
                          {operation_station.date}
                        </span>
                        {!is_on_way && (
                          <span className="text-xxs text-slate-500 font-light underline">
                            {getDaysFromDate(operation_station.date)}
                          </span>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              }
            </div>
          )}

          {/* СТАНЦИЯ ПРИБЫТИЯ */}
          {destination_station && (
            <div className="w-56 px-4 border-r border-slate-400 border-dashed leading-none">
              <div>
                {operation_station?.name === destination_station.name && (
                  <div className="relative">
                    <Icon
                      icon="flag"
                      className="w-1.5 text-rose-600 absolute top-1 -left-3"
                    />
                  </div>
                )}
                <div className="uppercase font-semibold text-sm leading-none truncate">
                  <Tooltip
                    content={
                      <div>
                        {`ЕСР ${destination_station.code}`}
                        <br />
                        {destination_station.name}
                        <br />
                        {destination_station.railway}
                      </div>
                    }
                  >
                    <span
                      className="whitespace-nowrap cursor-pointer"
                      onClick={() => {
                        setFilterValues({
                          rootKey: filterKey,
                          field: 'to_station_name',
                          values: [destination_station.name],
                        });
                      }}
                    >
                      {destination_station.name}
                    </span>
                  </Tooltip>
                </div>
              </div>

              {destination_station.date ? (
                <div className="flex items-center gap-2">
                  <Tooltip content="Добавить в фильтр дату прибытия">
                    <span
                      className="text-xs text-slate-500 font-bold cursor-pointer"
                      onClick={() => {
                        activateFilter('arrival_estimate');
                        setFilterValues({
                          rootKey: filterKey,
                          field: 'arrival_estimate',
                          values: [
                            dayjs(destination_station.date).toDate(),
                            dayjs(destination_station.date).toDate(),
                          ],
                        });
                      }}
                    >
                      {destination_station.date}
                    </span>
                  </Tooltip>

                  <span className="text-xxs text-slate-500 font-light">
                    {getDaysFromDate(destination_station.date)}
                  </span>
                </div>
              ) : (
                <div className="h-4" />
              )}

              <div className="flex mt-2.5 gap-2.5 items-center">
                <Tooltip content="Добавить в фильтр эту цель рейса">
                  <div>
                    <ObjectiveStatus
                      objective={objective}
                      onClick={() => {
                        const value = cx({
                          Погрузка: objective === 'LOADING',
                          Выгрузка: objective === 'UNLOADING',
                          Ремонт: objective === 'REPAIR',
                          'Промывка/пропарка': objective === 'WASHING',
                          'Не используется': objective === 'DONTUSE',
                        });

                        const newValue =
                          filters['to_operation'] === undefined
                            ? [value]
                            : !includes(filters['to_operation'], value)
                            ? [...filters['to_operation'], value]
                            : filters['to_operation'];

                        if (includes(filters['to_operation'], value)) return;

                        setFilterValues({
                          rootKey: filterKey,
                          field: 'to_operation',
                          values: newValue,
                        });
                      }}
                    />
                  </div>
                </Tooltip>{' '}
                {!is_on_way && (
                  <Tooltip content="Длительность текущей операции">
                    <span
                      className="text-xxs text-black cursor-pointer"
                      onClick={() => {
                        activateFilter('op_duration');
                        setFilterValues({
                          rootKey: filterKey,
                          field: 'op_duration',
                          values: [op_duration, op_duration],
                        });
                        activateFilter('is_idle');
                      }}
                    >
                      <span
                        className={cx('font-black text-xs', {
                          'text-red-500': op_duration_exceed,
                        })}
                      >
                        {op_duration}
                      </span>{' '}
                      сут.
                    </span>
                  </Tooltip>
                )}
              </div>
            </div>
          )}

          <div className="max-w-[380px]">
            <div className="flex flex-row gap-2.5">
              <div className="flex flex-col gap-2.5 pt-1">
                {return_station && (
                  <div className="flex flex-row gap-1">
                    <div
                      className={cx(
                        { 'border-slate-300': !return_station },
                        'flex w-3.5 h-3.5 border border-black rounded items-center justify-center',
                      )}
                    >
                      <Icon
                        icon="return"
                        className={cx(
                          { 'text-slate-400': !return_station },
                          'w-2',
                        )}
                      />
                    </div>

                    <Tooltip
                      content={cx({
                        'Накладная предъявлена': return_waybill_status,
                        'Накладная не предъявлена': !return_waybill_status,
                      })}
                    >
                      <div
                        className={cx(
                          {
                            'border-slate-300 text-slate-400':
                              !return_waybill_status,
                          },
                          'flex w-3.5 h-3.5 border border-black rounded items-center justify-center',
                        )}
                      >
                        <Icon icon="document" className="h-2.5" />
                      </div>
                    </Tooltip>
                  </div>
                )}

                {proposed_trip && (
                  <div className="flex flex-row gap-1">
                    <div
                      className={cx(
                        { 'bg-slate-300': !proposed_trip },
                        'flex w-3.5 h-3.5 rounded items-center justify-center bg-violet-700',
                        proposed_trip?.status,
                      )}
                    >
                      <Icon icon="ai" className="w-3 text-white" />
                    </div>

                    <Tooltip
                      content={cx({
                        'Рейс одобрен': includes(
                          [
                            StatusEngLabelsEnum.Approved,
                            StatusEngLabelsEnum.Sent,
                            StatusEngLabelsEnum.Formed,
                            StatusEngLabelsEnum.Error,
                            StatusEngLabelsEnum.OnWay,
                            StatusEngLabelsEnum.Completed,
                            StatusEngLabelsEnum.RejectedByDispatcher,
                            StatusEngLabelsEnum.OffNormalSituation,
                            StatusEngLabelsEnum.ErrorOnSending,
                          ],
                          proposed_trip.status,
                        ),
                        'Рейс не одобрен': includes(
                          [
                            StatusEngLabelsEnum.New,
                            StatusEngLabelsEnum.PreApproved,
                            StatusEngLabelsEnum.PreRejected,
                          ],
                          proposed_trip.status,
                        ),
                        'Рейс отклонён':
                          proposed_trip.status === StatusEngLabelsEnum.Rejected,
                      })}
                    >
                      <div
                        className={cx(
                          'flex w-3.5 h-3.5 border border-black rounded items-center justify-center',
                          {
                            'border-black text-black': includes(
                              [
                                StatusEngLabelsEnum.Approved,
                                StatusEngLabelsEnum.Sent,
                                StatusEngLabelsEnum.Formed,
                                StatusEngLabelsEnum.Error,
                                StatusEngLabelsEnum.OnWay,
                                StatusEngLabelsEnum.Completed,
                                StatusEngLabelsEnum.RejectedByDispatcher,
                                StatusEngLabelsEnum.OffNormalSituation,
                                StatusEngLabelsEnum.ErrorOnSending,
                              ],
                              proposed_trip.status,
                            ),
                            'border-slate-300 text-slate-300': includes(
                              [
                                StatusEngLabelsEnum.New,
                                StatusEngLabelsEnum.PreApproved,
                                StatusEngLabelsEnum.PreRejected,
                              ],
                              proposed_trip.status,
                            ),
                            'border-rose-600 text-rose-600':
                              proposed_trip.status ===
                              StatusEngLabelsEnum.Rejected,
                          },
                        )}
                      >
                        <Icon
                          icon={
                            proposed_trip.status ===
                            StatusEngLabelsEnum.Rejected
                              ? 'thumbs-down'
                              : 'thumbs-up'
                          }
                          className="h-2"
                        />
                      </div>
                    </Tooltip>
                  </div>
                )}
              </div>

              <div className="flex flex-col leading-none gap-1">
                {return_station && (
                  <div
                    className="uppercase font-semibold text-sm leading-none"
                    onClick={() => {
                      activateFilter('return_station_name');
                      setFilterValues({
                        rootKey: filterKey,
                        field: 'return_station_name',
                        values: [return_station.name],
                      });
                    }}
                  >
                    <Tooltip content="Добавить в фильтр эту станцию возврата">
                      <span className="truncate cursor-pointer">
                        {return_station.name}
                      </span>
                    </Tooltip>
                  </div>
                )}

                {proposed_trip && (
                  <div className="flex flex-row gap-2 items-center">
                    {proposed_trip?.station && (
                      <Tooltip content="Добавить в фильтр эту станцию возврата">
                        <div className="uppercase font-semibold text-sm leading-none max-w-[200px] truncate">
                          <span
                            className="cursor-pointer"
                            onClick={() => {
                              activateFilter('return_station_name');
                              setFilterValues({
                                rootKey: filterKey,
                                field: 'return_station_name',
                                values: [proposed_trip.station.name],
                              });
                            }}
                          >
                            {proposed_trip.station.name}
                          </span>
                        </div>
                      </Tooltip>
                    )}
                    {proposed_trip?.operation && (
                      <ObjectiveStatus
                        objective={proposed_trip.operation}
                        className="!cursor-default"
                      />
                    )}{' '}
                    {proposed_trip.operation !==
                      OperationEngLabelsEnum['Dontuse'] && (
                      <Tooltip content="Показать все прогнозные рейсы в карточке вагона">
                        <div
                          className="text-xxs text-slate-500 underline cursor-pointer whitespace-nowrap"
                          onClick={() => {
                            setFilterValues({
                              rootKey: 'proposedTrips',
                              field: 'car_no',
                              values: [car.name],
                            });
                            navigate('/proposed-trips');
                          }}
                        >
                          {`Все прогнозные (${proposed_trips_count})`}
                        </div>
                      </Tooltip>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
